import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Spin,
  Space,
  QRCode,
  Card,
  message
} from "antd";
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationScand from '../../../../../assets/lottie/scand.json'
import './qr.css';

const { Title, Text } = Typography;
const Page = () => {
		const [ loading, setLoading ] = useState(false)
    const [ qrData, setQrData ] = useState(false)
    const [ infoData, setInfoData ] = useState(false)
    const [ step, setStep ] = useState(0)

    const [ type, setType ] = useState(false)
    const [ dataForm, setDataForm ] = useState(false);
    const { authLiff, isLogin, sleep } = useContext(MainContext);


    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code') || '-';
    const navigate = useNavigate();

    useEffect(() => {  
      if(isLogin==true){
      	if(code.length>=10){
         queryCode(code);
      	}else{
          onQr()
      	}
      }
    }, [isLogin]);  

		const queryCode = async(value) => {
      const qr_code = value.split('?code=').length==2? value.split('?code=')[1] : value
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/chinese_new_year/qr/scand/check', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                code:qr_code,
                sv:authLiff.getContext().type
              }),
          })
          .then((response) => response.json())
          .then((data) => {
           
                  setStep(data.step)
                  setQrData(data)
                  setInfoData(data.info)
                  setLoading(false)
                  message.info(data.message);
             
          })
          .catch((error) => {
              setLoading(false)
          });
    }

    const onScanAction = async() => {
      try {
          setLoading(true)
          authLiff
            .scanCodeV2()
            .then(async(result) => {
                  if(result && result.value){
                    queryCode(result.value)
                  }else{
                    setLoading(false)
                  }
            }).catch((error) => {
                setLoading(false)
                message.info('line app not support camera');
            });
        
      } catch(error) {
        setLoading(false)
      }
    }


    const onQr = async() => {
      setLoading(false)
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch('/api/liff/kuber/event/chinese_new_year/qr/scand/get', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                sv:authLiff.getContext().type
              }),
          })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
                  		setStep(data.step)
                      setQrData(data)
                      setInfoData(data.info)
                      setLoading(false)
                      message.info(data.message);
                  }
              }).catch((error) => {
              	setLoading(false)
              });
      } catch(error) {
      	setLoading(false)
      }
    }

	const RenderQr = ({data}) => {
	  return (
	        <QRCode
	          errorLevel="H"
	          bgColor="#ffffff"
	          bordered={true}
	          size={200}
	          value={data?.data}
	        />
	  )
	}


// <LinkAccount liffId={'1657440707-LEKd1RdJ'}>
  return (
    <LinkAccount liffId={'1657440707-LEKd1RdJ'}>
  	<Spin spinning={loading}>
	  	<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/chinese_new_year/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
		  	<div style={{padding:'120px 50px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
	          
            {step==1 && infoData && (<Card size={'small'} style={{backgroundColor:'#ff000090',border:'none'}}>
  						<img src={infoData.image} alt=""/>
              <div style={{textAlign:'center'}}>
  							<h2 style={{color:'#ffffff'}}>แสดง QR นี้กับเจ้าหน้าที่</h2>
  							<div className="bitkub-cny-qr">
  							  <RenderQr data={qrData}/>
  							</div>
  							<Text style={{color:'#ffffff'}}>เพื่อรับรางวัล {infoData.name} จากกิจกรรม</Text>
  						</div>
						</Card>)}



            {step==2 && (<Card size={'small'}><Result
                title="ท่านได้ร่วมกิจกรรมไปแล้ว"
                subTitle={'รับรางวัล '+infoData.name+' จากกิจกรรมแล้ว ขอให้ปีใหม่นี้เต็มไปด้วยความสุข เฮงๆ รวยๆ สุขภาพแข็งแรง ตลอดปีมังกรทอง 2025 ซินเจียยู่อี่ ซินนี้ฮวดไช้!'}
                icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
                style={{padding:0}}
              /></Card>)}

	          {step==0 && (<Card size={'small'}>
	            <Result
	              icon={<Lottie options={{
	                  animationData: animationScand,
	                  loop: false,
	                  autoplay: true,
	                }} height={200} width={200}/>}
	              title={'Scan QR'}
	              subTitle={"กรุณาทำการกดที่ปุ่ม Scan Qr"}
	              style={{padding:0}}
	              extra={[
	                <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
	                  Scan Qr
	                </Button>
	              ]}
	            />
	          </Card>)}

	       </div>
	    </div>
	  </Spin>
    </LinkAccount>
	  
  );
};


export default Page;