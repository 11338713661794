import { Routes, Route } from "react-router-dom";
import { MainProvider } from "./contexts/main";

import LayoutMain from "./layouts/main";
import PageIndex from "./pages/index";

import LiffKuberLink from "./pages/liff/kuber/link";
import LiffKuberQr from "./pages/liff/kuber/qr";
import LiffKuberWallet from "./pages/liff/kuber/wallet";
import LiffKuberTicket from "./pages/liff/kuber/ticket";
import LiffKuberHelp from "./pages/liff/kuber/help";
import LiffKuberEvent from "./pages/liff/kuber/event";
import LiffKuberFormKhaya from "./pages/liff/kuber/form/khaya";
import LiffKuberEventBitkubMate from "./pages/liff/kuber/event/bitkub_mate";
import LiffKuberEventHodlDayMap from "./pages/liff/kuber/event/hodl_day/map";
import LiffKuberEventHodlDayJoin from "./pages/liff/kuber/event/hodl_day/join";
import LiffKuberEventHodlDayScore from "./pages/liff/kuber/event/hodl_day/score";
import LiffKuberEventHodlDayActivity from "./pages/liff/kuber/event/hodl_day/activity";
import LiffKuberEventHodlDayStaff from "./pages/liff/kuber/event/hodl_day/staff";
import LiffKuberEventKubmartStaff from "./pages/liff/kuber/event/kubmart/staff";
import LiffKuberEventWellbeingCalendar from "./pages/liff/kuber/event/well_being/calendar";
import LiffKuberEventDiyStaff from "./pages/liff/kuber/event/diy/staff";

import LiffKuberEventBitkubSummitQr from "./pages/liff/kuber/event/bitkub_summit/qr";
import LiffKuberEventBitkubSummitStaff from "./pages/liff/kuber/event/bitkub_summit/staff";

import LiffKuberEventBitkubCNYQr from "./pages/liff/kuber/event/chinese_new_year/qr";
import LiffKuberEventBitkubCNYStaff from "./pages/liff/kuber/event/chinese_new_year/staff";


import LiffKuberEventAllHandsQr from "./pages/liff/kuber/event/all_hands/qr";
import LiffKuberEventAllHandsStaff from "./pages/liff/kuber/event/all_hands/staff";


import LiffKuberEventOutingCreate from "./pages/liff/kuber/event/outing_2024/create";
import LiffKuberEventOutingInvite from "./pages/liff/kuber/event/outing_2024/invite";
import LiffKuberEventOutingQuiz from "./pages/liff/kuber/event/outing_2024/quiz";
// import LiffKuberEventOutingBooking from "./pages/liff/kuber/event/outing_2024/booking";
// import LiffKuberEventOutingJoin from "./pages/liff/kuber/event/outing_2024/join";
// import LiffKuberEventOutingBookingRoom  from "./pages/liff/kuber/event/outing_2024/booking_room";
import LiffKuberEventOutingMenu from "./pages/liff/kuber/event/outing_2024/menu";
import LiffKuberEventOutingStaff from "./pages/liff/kuber/event/outing_2024/staff";

import PageTvMenu from "./pages/tv/menu";
import PageTvOffice from "./pages/tv/office";
import PageTvCheckin from "./pages/tv/checkin";
import PageTvMain from "./pages/tv/main";
import PageTvBitkubMate from "./pages/tv/bitkub_mate";
import PageTvOuting from "./pages/tv/outing_2024";


function App() {
  return (
    <MainProvider className="root_app">
      <Routes>
        <Route element={<LayoutMain />}>
          <Route exact path="/" element={<PageIndex />} />
          <Route exact path="/liff/kuber/link" element={<LiffKuberLink />} />
          <Route exact path="/liff/kuber/qr" element={<LiffKuberQr />} />
          <Route exact path="/liff/kuber/ticket" element={<LiffKuberTicket />} />
          <Route exact path="/liff/kuber/wallet" element={<LiffKuberWallet />} />
          <Route exact path="/liff/kuber/help/:id?" element={<LiffKuberHelp />} />
          <Route exact path="/liff/kuber/event" element={<LiffKuberEvent />} />
          <Route exact path="/liff/kuber/form_khaya" element={<LiffKuberFormKhaya />} />
          <Route exact path="/liff/kuber/event/bitkub_mate" element={<LiffKuberEventBitkubMate />} />
          <Route exact path="/liff/kuber/event/hodl_day/map" element={<LiffKuberEventHodlDayMap />} />
          <Route exact path="/liff/kuber/event/hodl_day/join" element={<LiffKuberEventHodlDayJoin />} />
          <Route exact path="/liff/kuber/event/hodl_day/score" element={<LiffKuberEventHodlDayScore />} />
          <Route exact path="/liff/kuber/event/hodl_day/staff" element={<LiffKuberEventHodlDayStaff />} />
          <Route exact path="/liff/kuber/event/hodl_day/activity/:id?" element={<LiffKuberEventHodlDayActivity />} />
          <Route exact path="/liff/kuber/event/kubmart/staff" element={<LiffKuberEventKubmartStaff />} />
          <Route exact path="/liff/kuber/event/well_being/calendar" element={<LiffKuberEventWellbeingCalendar />} />
          <Route exact path="/liff/kuber/event/bitkub_summit/qr" element={<LiffKuberEventBitkubSummitQr />} />
          <Route exact path="/liff/kuber/event/bitkub_summit/staff" element={<LiffKuberEventBitkubSummitStaff />} />
          <Route exact path="/liff/kuber/event/diy/staff" element={<LiffKuberEventDiyStaff />} />
          
          <Route exact path="/liff/kuber/event/all_hands/qr" element={<LiffKuberEventAllHandsQr />} />
          <Route exact path="/liff/kuber/event/all_hands/staff" element={<LiffKuberEventAllHandsStaff />} />

          <Route exact path="/liff/kuber/event/outing_2024/create" element={<LiffKuberEventOutingCreate />} />
          <Route exact path="/liff/kuber/event/outing_2024/invite" element={<LiffKuberEventOutingInvite />} />
          <Route exact path="/liff/kuber/event/outing_2024/menu" element={<LiffKuberEventOutingMenu />} />
          <Route exact path="/liff/kuber/event/outing_2024/staff" element={<LiffKuberEventOutingStaff/>} />
          <Route exact path="/liff/kuber/event/outing_2024/quiz" element={<LiffKuberEventOutingQuiz />} />

          <Route exact path="/liff/kuber/event/chinese_new_year/qr" element={<LiffKuberEventBitkubCNYQr />} />
          <Route exact path="/liff/kuber/event/chinese_new_year/staff" element={<LiffKuberEventBitkubCNYStaff />} />
          
          <Route exact path="/tv/menu" element={<PageTvMenu />} />
          <Route exact path="/tv/office" element={<PageTvMenu />} />
          <Route exact path="/tv/back_to_office" element={<PageTvMenu />} />
          <Route exact path="/tv/checkin" element={<PageTvCheckin />} />
          <Route exact path="/tv/main" element={<PageTvMain />} />
          <Route exact path="/tv/bitkub_mate" element={<PageTvBitkubMate />} />
          <Route exact path="/tv/outing_2024/675a6d8bd9a0a7254c538816" element={<PageTvOuting/>} />

          <Route path="*" element={<div>Notfound</div>} />
        </Route>
      </Routes>
    </MainProvider>
  );
}

export default App;
