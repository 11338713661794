import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Spin,
  Space,
  QRCode,
  Avatar,
  message,
  Card
} from "antd";
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import './qr.css';

const { Title, Text, Paragraph } = Typography;
const Page = () => {
		const [ loading, setLoading ] = useState(true)
    const [ qrData, setQrData ] = useState(false)
    const [ step, setStep ] = useState(0)
    const [ dataScan, setDataScan ] = useState(false)
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code') || '-';

    useEffect(() => {  
      if(isLogin==true){
      	if(code.length>=10){
         queryCode(code);
      	}else{
         onQr()
        }
      }
    }, [isLogin]);  




		const queryCode = async(value) => {
			setLoading(true)
      const qr_code = value.split('?code=').length==2? value.split('?code=')[1] : value
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/all_hands/qr/check', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                code:qr_code,
                sv:authLiff.getContext().type
              }),
          })
          .then((response) => response.json())
          .then((data) => {
           				
                  setDataScan(data.info)
                  setStep(3)
                  setLoading(false)
                  message.info(data.message);
             
          })
          .catch((error) => {
          		setStep(4)
              setLoading(false)
          });
    }

		
    const onQr = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/all_hands/qr/gen`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
                  	setStep(1)
                      setQrData(data)
                      setLoading(false)
                  }
              }).catch((error) => {
              	setLoading(false)
              });
      } catch(error) {
      	setLoading(false)
      }
    }

	const RenderQr = ({data}) => {
	  return (
	        <QRCode
	          errorLevel="H"
	          bgColor="#ffffff"
	          bordered={true}
	          size={200}
	          value={data?.data}
	        />
	  )
	}

	const showQr = () => {
		setStep(2)
	}

	const backMenu = async() => {
		setStep(1)
	}



	//<LinkAccount liffId={'1657440707-nwLyO7yR'}>
  return (
  	<LinkAccount liffId={'1657440707-nwLyO7yR'}>
	  	<Spin spinning={loading}>
		      {(step==0 || step==1) && (
						<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/all_hands/bg_index.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
					  	<div className="mb-10">

								{step==1 && (<div className="all-hands-menu">
										<Button onClick={()=>showQr()} block size={'large'} className="all-hands-btn">Check In</Button>
								</div>)}
							</div>
						</div>
					)}

					{step==2 && (
						<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/all_hands/bg_main.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
					  	<div className="mb-10">
								<div className="all-hands-frame" style={{textAlign:'center'}}>
									<h2>{'Show this Qr'}</h2>
						      <Card className="all-hands-qr">
						        {qrData && <RenderQr data={qrData}/>}
						        
						      </Card>
						      <Button onClick={()=>backMenu()} block size={'large'} className="all-hands-btn">Back</Button>
					      </div>
							</div>
						</div>
					)}
					
					{step==3 && (<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/all_hands/bg_main.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
					  	<div className="mb-10">
								<div className="all-hands-frame" style={{textAlign:'center'}}>
									<Card size={'small'} className="all-hands-frame-inner">
									  {dataScan && (<Result
									    title={dataScan?.title}
									    subTitle={dataScan?.name+' '+dataScan?.sub_title}
									    icon={
									      <div style={{position:'relative',width:100,margin:'30px auto 0'}}>
									        <Avatar size={100} src={dataScan?.avatar} />
									      </div>
									    }
									    extra={[
									      <Button type="primary" block size="large" onClick={()=>{
									          // authLiff.closeWindow();
									      }}>
									        Done
									      </Button>
									    ]}
									    style={{padding:0}}
									  >
									  </Result>)}
									</Card>
					      </div>
							</div>
						</div>
					)}
					
					{step==4 && (<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/all_hands/bg_main.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
					  	<div className="mb-10">
								<div className="all-hands-frame" style={{textAlign:'center'}}>
									<Card size={'small'} className="all-hands-frame-inner">
									  <Result
									    title={'QR Code Invalid'}
									    subTitle={'QR Code ไม่ถูกต้อง'}
									    extra={[
									      <Button type="primary" block size="large" onClick={()=>{
									          // authLiff.closeWindow();
									      }}>
									        Close
									      </Button>
									    ]}
									    style={{padding:0}}
									  >
									  </Result>
									</Card>
					      </div>
							</div>
						</div>
					)}
					
		  </Spin>
		 </LinkAccount>
  );
};


export default Page;